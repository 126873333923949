// 角色业务范围
export default {
  map: {
    1: '全部',
    2: '商务',
    3: '运营',
  },
  enum: {
    all: 1,
    commercial: 2,
    operational: 3,
  },
};
