// 角色状态
export default {
  map: {
    1: '启用',
    2: '禁用',
  },
  enum: {
    enabled: 1,
    disabled: 2,
  },
};
